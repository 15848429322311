<template>
  <!--
    The view for the IntroList-component.
    Used to display the themes Intros.
  -->
  <div 
    v-if="introOutroId" 
    class="introOverview"
  >
    <IntroList
      ref="introList"
      :intro-outro-id="introOutroId"
      @reloadAuditLogs="reloadAuditLogs"
    />
    <DatabaseAuditLogList
      ref="databaseAuditLogList"
      :intro-outro-id="introOutroId"
      @reload="reloadIntroList"
    />
  </div>
</template>

<script>
export default {
  name: "IntroOverview",
  components: {
    IntroList: () => import('@/components/VideoPostprocessing/IntroOutro/IntroList.vue'),
    DatabaseAuditLogList: () => import('@/components/DatabaseAuditLogs/DatabaseAuditLogsList.vue')
  },
  props: {
    introOutroId: {
      type: String,
      required: true
    }
  },
  metaInfo () {
    return {
      title: this.$t('videoPostProcessing.title')
    }
  },
  methods: {
    reloadAuditLogs () {
      this.$refs.databaseAuditLogList.reloadAuditLogs();
    },
    reloadIntroList () {
      this.$refs.introList.getIntroOutroAndCloseSidebar();
    }
  }
}
</script>
